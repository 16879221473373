@use "/src/assets/css/partials/fonts";

.stock-editor-container {
  display: flex;
  padding: 6px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .editor-btn {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

.reward-code-title {
  color: #121212;
  font-size: 13px;
  font-family: fonts.$SF-Pro-Display;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin: 1rem 0;
}

.reward-delete-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.no-redeemed-code {
  margin: 1rem 0;
  color: #121212;
  font-size: 13px;
  font-family: fonts.$SF-Pro-Display;
  font-style: normal;
  line-height: 18px;
}

.redeemed-email {
  color: #bebebe;
  font-family: fonts.$Source-Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  height: 60%;
  align-items: center;
}
