@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

:global {
  .ant-select-multiple {
    .ant-select-selection-item {
      min-height: 1.5rem;
      height: initial;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }

  .select-style {
    min-height: 3rem;
    background: theme.$white;
    border: 1px solid rgba(128, 128, 128, 0.16);
    border-radius: 0.75rem;
    font-family: fonts.$Source-Sans;
    font-weight: 400;
    font-size: 0.875rem;
    align-items: center;
  }

  .form-select.ant-select {
    .ant-select-selector {
      @extend .select-style;
      input {
        min-height: 3rem;
      }
      .ant-select-selection-item {
        line-height: normal;
      }
    }

    width: 100%;
    min-width: 150px;
  }
  .label {
    font-family: fonts.$Source-Sans;
    color: theme.$basic-900;
    font-weight: 600;
    font-size: 1rem;
    display: block !important;
    margin-bottom: 6px;
  }

  .empty-label {
    height: 0.5rem;
  }

  .subLabel {
    font-family: fonts.$Source-Sans;
    color: theme.$basic-900;
    font-weight: 400;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 6px;
  }
}

.form-control {
  margin-bottom: 1.25rem;
}

.text-danger {
  color: red;
}
