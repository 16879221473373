.assessment-create {
  &-question-heading {
    .ant-space-item {
      display: inline-flex;
    }
    .ant-space-item:not(:last-child) {
      margin-right: 0.3rem;
    }
    &-controller {
      font-size: 30px;
      cursor: pointer;
    }
    align-items: center;
    &-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
}
