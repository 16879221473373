@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.btn-style {
  font-family: fonts.$Inter;
  font-weight: 400;
  font-size: 0.875rem;
  cursor: pointer;
}
.add-btn {
  @extend .btn-style;
  color: theme.$primary-600;
  margin-top: -0.5rem;
  display: block;
}
.remove-btn {
  @extend .btn-style;
  color: theme.$danger;
  margin-top: -0.5rem;
  display: block;
}
.flex-div {
  @include flex(space-between, center);
  margin-bottom: 1.5rem;
}
.form-control {
  margin-bottom: 1.5rem;
}
