@use "/src/assets/css/partials/theme";
@use "/src/assets/css/partials/fonts";
@import "/src/assets/css/mixins/mixins.scss";
:global {
  .no-header {
    line-height: 0;
  }
  .header {
    // @include flex(space-between, center);
    margin-bottom: 1.25rem;
    .wrap-header-content {
      display: flex;
      flex-wrap: wrap;
    }
    &__row {
      // background-color: orange;
      margin-bottom: 1.25rem;

      width: 100%;
      @include flex(flex-end, center);
      .ant-space-item:nth-child(1) {
        margin-right: auto;
      }
      &--xtitle {
        //styleName: Headline/h5;
        font-family: fonts.$Source-Sans;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        margin-bottom: 0;
      }
    }
  }
  .custom-table {
    .custom-switch.ant-switch {
      background-color: theme.$basic-500;
      &.ant-switch-checked {
        background-color: theme.$purple;
      }
    }
  }
  .search-input {
    .ant-btn-primary {
      background-color: transparent;
      box-shadow: none;
    }
  }
  .table-header {
    @include flex(space-between, center);
    margin-bottom: 1.25rem;
    .title {
      font-family: fonts.$Source-Sans;
      font-weight: bold;
      font-size: 1.25rem;
      margin-bottom: 0px;
    }
    .search-container {
      display: flex;
    }
    .margin-left {
      margin-left: 10px;
    }
  }
  .pagination-styles {
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    font-family: fonts.$Inter;
    font-weight: 500;
    font-size: 0.875rem;
  }
  .pagination-margin {
    margin-top: 1rem;
  }
  .table-check {
    .ant-checkbox-wrapper {
      .ant-checkbox-checked {
        &::after {
          border-color: theme.$primary-600;
        }
        .ant-checkbox-inner {
          border-color: theme.$primary-600;
          background-color: theme.$primary-600;
        }
      }
      &:hover {
        .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner {
          border-color: theme.$primary-600;
        }
      }
    }
  }
  .custom-table {
    .ant-table {
      border-radius: 0.5rem;
      .ant-table-container {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        overflow-x: auto;
        thead.ant-table-thead {
          background-color: theme.$white;

          tr > th {
            background-color: theme.$white;
            color: theme.$primary-600;
            font-family: fonts.$Inter;
            font-style: normal;
            font-weight: 700;
            font-size: 0.875rem;
            @extend .table-check;
          }
        }

        thead.ant-table-thead tr:first-child th:first-child {
          border-top-left-radius: 0.5rem !important;
        }

        thead.ant-table-thead tr:first-child th:last-child {
          border-top-right-radius: 0.5rem !important;
        }

        .ant-table-tbody {
          tr > td {
            font-family: fonts.$Inter;
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            color: theme.$basic;
            @extend .table-check;
            .ant-table-wrapper.custom-table {
              margin-left: 0px;
              // &::before {
              //   content: "";
              //   position: absolute;
              //   height: 100%;
              //   border-left: 3px solid #808080 !important;
              //   left: -0.2rem;
              // }
            }
          }
          tr.ant-table-row-selected > td {
            background-color: theme.$white;
            @extend .table-check;
          }
          tr {
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
  ul.ant-pagination {
    display: flex;
    justify-content: flex-end;
    li.ant-pagination-item {
      @extend .pagination-styles;
      a {
        color: theme.$gray;
      }
      &:hover {
        a {
          color: theme.$gray;
        }
      }
      &.ant-pagination-item-active {
        @extend .pagination-styles;
        color: theme.$white;
        background: theme.$primary-600;
        &:hover {
          a {
            color: theme.$white;
          }
        }
        a {
          color: theme.$white;
        }
      }
    }
    .ant-pagination-options {
      .ant-pagination-options-size-changer.ant-select {
        &.ant-select-focused {
          .ant-select-selector {
            box-shadow: none;
          }
        }
        .ant-select-selector {
          @extend .pagination-styles;
          color: theme.$gray;
        }
      }
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      .ant-pagination-item-link {
        @extend .pagination-styles;
        color: theme.$gray;
      }
    }
  }
}
