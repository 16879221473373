.add-translation {
  margin-bottom: 1.5rem;
}
.langs {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1rem;
}

.delete-btn {
  margin-top: 1.9rem;
}
