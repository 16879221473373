@use "/src/assets/css/partials/fonts";
@import "/src/assets/css/mixins/mixins.scss";
.wellbeing-calendar {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}

.select-icon {
  position: relative;
  background: white;
  border-radius: 0.7rem;
}

:global {
  .no-highlight {
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border-color: transparent;
      box-shadow: none;
    }
  }
}
