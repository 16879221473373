@mixin flex($justify: normal, $align: normal, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin font($size: inherit, $weight: inherit, $color: inherit) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}
