@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.desc-container {
  display: grid;
  grid-template-columns: 120px 1fr;
  column-gap: 24px;
  .partner-img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
  }

  .description {
    border: 1px solid theme.$basic-500;
    padding: 1.25rem;
    border-radius: 8px;
    .title {
      font-weight: 700;
      font-size: 2rem;
      font-family: fonts.$Source-Sans;
      margin-bottom: 4px;
      line-height: 36px;
    }
    .type {
      font-weight: 700;
      font-size: 1.15rem;
      font-family: fonts.$Source-Sans;
      margin-bottom: 4px;
      line-height: 22px;
    }
    .partner-desc {
      margin-top: 16px;
      font-weight: 700;
      font-size: 1.15rem;
      font-family: fonts.$Source-Sans;
      .detail {
        font-weight: 400;
        font-family: fonts.$Source-Sans;
        font-size: 1rem;
      }
    }
  }
}
.partner-reward {
  margin-top: 2rem;
  @include flex(space-between, center);
  margin-bottom: 16px;
  .partner-title {
    font-weight: 600;
    font-family: fonts.$Source-Sans;
    font-size: 1rem;
  }
}
