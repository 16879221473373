@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.content {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
}
.content-style {
  font-family: fonts.$Source-Sans;
  font-weight: 700;
}
.title {
  @extend .content-style;
  font-size: 1.25rem;
  margin-bottom: 0px;
}
.content-title {
  @extend .content-style;
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
}
.content-img {
  margin-bottom: 1.5rem;
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 1rem;
    max-height: 350px;
  }
}
.description {
  margin-bottom: 1.5rem;
  h2.desc {
    font-family: fonts.$Source-Sans;
    font-weight: 700;
    font-size: 1rem;
    margin: 0;
  }
  p {
    font-family: fonts.$Source-Sans;
    font-weight: 500;
    font-size: 1.15rem;
    margin-bottom: 0px;
  }
}

.detail-card {
  display: grid;
  flex-direction: column;
  grid-gap: 1.5rem;
}

.mt-2 {
  margin-top: 1.5rem;
}
.recipe-img {
  height: 230px;
  width: 100%;
  border-radius: 1rem;
  object-fit: cover;
  margin-bottom: 0.5rem;
}
.desc {
  font-family: fonts.$Source-Sans;
  font-weight: 700;
  font-size: 1rem;
  margin: 1rem;
}

.content-view {
  margin-bottom: 1rem;
}

:global {
  .description-html {
    color: #1b1b1c !important;
  }
}
