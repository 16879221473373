@use "/src/assets/css/partials/fonts";
@import "/src/assets/css/mixins/mixins.scss";
.department {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}

.department-configuration-hyperlink {
  color: #000;
  font-family: fonts.$Source-Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.department-configuration-details {
  @extend .department-configuration-hyperlink;
  font-weight: 400;
  margin: 1rem 0;
}
