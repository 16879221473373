@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

.customSwitch {
  display: flex;
  justify-content: space-between;
}

.customSwitch--label {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 6px;
}
.subLabel {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 6px;
}

.add-notification {
  margin: 1.5rem 0;
}
.notifications {
  margin-bottom: 1.5rem;
}
.block {
  display: block;
  width: 100%;
  margin-top: 1.5rem;
}

.flex-notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.enable-notification {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.checkInIconRow {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
}
