@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

.title {
  font-family: fonts.$Source-Sans;
  color: theme.$default;
  font-weight: 700;
  font-size: 1.875rem;
  text-align: center;
  margin-bottom: 2.5rem;
}
.description {
  font-family: fonts.$Source-Sans;
  color: theme.$default;
  font-weight: 600;
  font-size: 1rem;

  text-align: center;
  margin-bottom: 2.5rem;
}
.get-mail {
  @extend .description;
  color: #adadad;
  padding-top: 2.5rem;
  
}
.resend {
  @extend .description;
  cursor: pointer;
  margin-bottom: 0px;
}
