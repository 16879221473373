.sub-question {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.subQuestion-header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1rem;
  row-gap: 1rem;
  justify-content: space-between;
}
.sub-title {
  font-weight: 700;
  font-size: 1.15rem;
}
.question-configs {
  display: grid;
  grid-template-columns: 250px 250px 250px auto;
  row-gap: 1rem;
  column-gap: 1rem;
  align-items: center;
}

.question-title {
  display: grid;
  grid-template-columns: 1fr 100px;
  column-gap: 1rem;
}
