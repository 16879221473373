@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.multi-lang-box {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.5rem;
  
}

.flex-div {
  @include flex(space-between, center);
  margin-bottom: 1.5rem
}
.add-btn {
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
}
.btn-style {
    font-family: fonts.$Inter;
    font-weight: 400;
    font-size: 0.875rem;
    cursor: pointer;
  }
.remove-btn {
  @extend .btn-style;
  color: theme.$danger;
  margin-top: -0.5rem;
  display: block;
}
.form-control{
    margin-bottom: 1.5rem;
}