@use "/src/assets/css/partials/theme";
@use "/src/assets/css/partials/fonts";
.form-control {
  margin-bottom: 1.5rem;
}
.label {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 600;
  font-size: 1rem;
  display: block;
  margin-bottom: 6px;
}
