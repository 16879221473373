.header {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 1rem;
  column-gap: 1rem;

  .question-number {
    font-weight: 700;
    font-size: 1.125rem;
  }

  .question-configs {
    display: grid;
    grid-template-columns: 250px 250px 250px auto;
    row-gap: 1rem;
    column-gap: 1rem;
    align-items: baseline;
  }

  .emojis {
    font-weight: 600;
    font-size: 0.875rem;
  }

  .question-title {
    display: grid;
    grid-template-columns: 1fr 100px;
    column-gap: 1rem;
  }
  // .question-title-emoji {
  //   grid-template-columns: auto 1fr 100px;
  // }
}
