@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

:global {
  .input-style {
    height: 3rem;
    background: theme.$white;
    border: 1px solid rgba(128, 128, 128, 0.16);
    border-radius: 0.75rem;
    font-family: fonts.$Source-Sans;
    font-weight: 400;
    font-size: 0.875rem;
    width: 100%;
    box-shadow: none;
    padding: 10px;
  }
  .input-style:focus {
    outline-width: 0;
  }
}

.label {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 6px;
}
.subLabel {
  display: block;
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 6px;
}

.form-control {
  margin-bottom: 1.5rem;
}
