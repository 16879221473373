@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";

.desc-container {
  border: 1px solid theme.$basic-500;
  border-radius: 4px;
  padding: 4px 16px;
}

.header {
  @include flex(space-between, center);
  margin-bottom: 8px;
  .title {
    font-weight: 600;
    font-size: 0.875rem;
    font-family: fonts.$Source-Sans;
  }
  .see-btn {
    @extend .title;
    cursor: pointer;
  }
}

.item-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.view-grid {
  display: grid;
  column-gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  grid-template-columns: 0.5fr 0.3fr auto;
}
.item-title {
  margin-left: 1rem;
}
.sub-title {
  font-weight: 600;
  font-size: 0.875rem;
  font-family: fonts.$Source-Sans;
  color: #121212;
}
.main-title {
  font-weight: 700;
  font-family: fonts.$Source-Sans;
  font-size: 1rem;
}
.item-id {
  padding: 0px 0.5rem;
}
.company-title {
  font-size: 12px;
  font-weight: 400;
  color: #121212;
}
.points {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
