@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.roles {
  .flex {
    @include flex(space-between, center);
    margin-bottom: 1.25rem;
    .title {
      font-family: fonts.$Source-Sans;
      font-weight: 700;
      font-size: 1.25rem;
      margin-bottom: 0px;
    }
  }
}
