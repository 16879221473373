@use "/src/assets/css/partials/theme";
@use "/src/assets/css/partials/fonts";
$dir: var(--direction);
.App {
  direction: $dir;
}
.text-danger {
  color: #fc3400;
}
.anchor {
  color: black;
  font-weight: 700 !important;
  font-size: 1.125rem !important;
  &:hover {
    color: black !important;
  }
  :active {
    color: black !important;
  }
}
.ant-pagination-total-text {
  margin-right: auto;
  font-family: fonts.$SF-Pro-Display;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.tag-style {
  font-family: fonts.$Inter;
  font-weight: 700;
  font-size: 0.75rem;
  margin-right: 0;
  padding: 0.125rem 0.875rem;
  border-radius: 2.25rem;
  border: none;
}
.active-tag.ant-tag {
  @extend .tag-style;
  color: theme.$success;
  background-color: rgba($color: theme.$success, $alpha: 8%);
}
.planned-tag.ant-tag {
  @extend .tag-style;
  color: #0aaddf;
  background-color: rgba(10, 173, 223, 0.08);
}
.inprogress-tag.ant-tag {
  @extend .tag-style;
  color: #49bf26;
  background-color: #f1faee;
}
.de-active-tag.ant-tag {
  @extend .tag-style;
  color: theme.$gray;
  background-color: rgba($color: theme.$gray, $alpha: 8%);
}
.pending-tag,
.unpublished-tag {
  @extend .tag-style;
  color: theme.$info;
  background-color: rgba($color: theme.$info, $alpha: 8%);
}
.custom-switch.ant-switch {
  background-color: theme.$basic-500;
  &.ant-switch-checked {
    background-color: theme.$primary-500;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #121212;
  border-color: #121212;
}
// .ant-checkbox-disabled .ant-checkbox-inner {
//   background-color: #fff;
// }
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.cut-text {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.search-input {
  background: white;
  border-radius: 12px;
  padding: 0.2rem;
  font-size: 2rem !important;
  .ant-input-group {
    height: 100%;
  }
  input {
    font-size: 1rem;
    font-weight: 400;
    height: 100%;
  }
  .ant-input-search-button {
    border: none;
    border-radius: 0px 10px 10px 0px !important;
    // background-color: orange;
  }
  .ant-input-group-addon {
    background-color: transparent;
  }
}

.ant-picker.filter-date-picker,
.ant-picker.dashboard-date-range-picker {
  padding: 8px 14px;
  border: none;
  border-radius: 12px;
  min-width: 200px;
  color: black !important;
  input::placeholder {
    color: black;
    font-weight: 600;
  }
  .ant-picker-suffix {
    color: black;
  }
}

.ant-picker.dashboard-date-range-picker {
  padding: 4px 14px;
}

.ant-picker.filter-date-picker-dark {
  border: none;
  border-radius: 12px;
  width: 150px;
  height: 40px;
  color: #f4f4f4 !important;
  background-color: #303030;
  input::placeholder {
    color: #f4f4f4;
    font-weight: 600;
  }
  .ant-picker-suffix {
    color: #f4f4f4;
  }
}

.filter-select.ant-select {
  height: 38px;
  min-width: 150px;
  // color: black !important;
  .ant-select-selector {
    border-radius: 12px !important;
    border: none;
    height: 100%;
    align-items: center;
  }
  .ant-select-selection-placeholder,
  .ant-select-arrow {
    color: black !important;
    font-weight: 600;
  }
}

.filter-select-dark.ant-select {
  height: 38px;
  width: 140px;
  // color: black !important;
  .ant-select-selector {
    border-radius: 12px !important;
    border: none;
    height: 100%;
    align-items: center;
    background-color: #303030;
  }
  .ant-select-selection-placeholder,
  .ant-select-arrow,
  .ant-select-clear,
  .ant-select-selection-item {
    color: #f4f4f4 !important;
    font-weight: 600;
    background: none;
  }
}

.dashboard-filter-select.ant-select {
  height: 32px;
  min-width: 150px;
  .ant-select-selector {
    border-radius: 10px !important;
    border: none;
    border: 1px solid #80808029;
    height: 100%;
    align-items: center;
    .ant-select-selection-item {
      color: #303030 !important;
      font-weight: 600 !important;
      font-family: fonts.$Source-Sans;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
    }
  }
  .ant-select-selection-placeholder,
  .ant-select-arrow {
    color: #303030 !important;
    font-weight: 600 !important;
    font-family: fonts.$Source-Sans;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
  }
}

.margin-right-5 {
  margin-right: 0.313rem;
}

.cursor-pointer {
  cursor: pointer;
}

.child-row {
  height: 30px;
  width: 30px; /* Adjust the width to your desired length */
  position: absolute;
  top: 0;
  left: 22px;
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  .ant-space {
    margin-left: 28px;
  }
}

.display-flex {
  display: flex;
}

.child-row::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 50%;
  background-color: transparent;
  border-left: 1px solid #adadad;
}

.child-row::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  background-color: transparent;
  border-bottom: 1px solid #adadad;
  width: 26px;
}
.parent-row {
  min-width: 170px;
}

.ant-popover-placement-top .ant-popover-arrow {
  display: none;
}

.cut-text-rtl {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  direction: rtl;
}
.cut-text-ltr {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.confirm-content {
  padding: 1.5rem 0px;
}
