@use "/src/assets/css/partials/theme";
@use "/src/assets/css/partials/fonts";
@import "/src/assets/css/mixins/mixins.scss";
:global {
  .menu-style {
    font-family: fonts.$Source-Sans;
    color: theme.$primary-600;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
  }
  .list-style {
    border-radius: 0.5rem;
    height: 46px;
    line-height: 46px;
  }
  .viwell-sidebar.ant-layout-sider {
    background-color: theme.$white;
    border-right: none;
    box-shadow: 1px 0px 0px #e2e8f0;
    .logo-div {
      position: relative;
      height: 4.875rem;
      @include flex(center, center);
      .menu-toggler {
        position: absolute;
        cursor: pointer;
        background: theme.$basic-200;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        @include flex(center, center);
      }
      .toggler-left {
        left: -12px !important;
      }
      .toggler-right {
        right: -12px;
      }
    }
    .viwell-menu {
      @extend .menu-style;
      border-right: none;
      margin-top: 1.5rem;
      padding: 0px 1.25rem;
      li.ant-menu-item {
        @extend .list-style;
        &.ant-menu-item-selected {
          background-color: theme.$primary-600;
          color: theme.$white;
          &:hover {
            color: theme.$white;
          }
          .ant-menu-item-icon > svg > path {
            fill: theme.$white;
          }
        }
        &:hover {
          color: theme.$primary-600;
        }
        &::after {
          border-right: none;
        }
        &:active {
          background-color: theme.$primary-600;
          color: theme.$white;
        }
      }
      li.ant-menu-submenu.ant-menu-submenu-inline {
        &.ant-menu-submenu-selected {
          color: theme.$primary-600;
          .ant-menu-item-icon > svg > path {
            fill: theme.$primary-600;
          }
        }
        &.ant-menu-submenu-open {
          .ant-menu-submenu-title {
            background-color: theme.$primary-600;
            color: theme.$white;
            .ant-menu-item-icon > svg > path {
              fill: theme.$white;
            }
            &:hover {
              color: theme.$white;
            }
            .ant-menu-submenu-arrow {
              color: theme.$white;
            }
          }
          ul.ant-menu-sub.ant-menu-inline {
            background-color: theme.$white;
            li.ant-menu-item {
              &.ant-menu-item-selected {
                background-color: theme.$light-gray;
                color: theme.$primary-600;
              }
              &:active {
                background-color: theme.$light-gray;
                color: theme.$primary-600;
              }
            }
          }
        }

        .ant-menu-submenu-title {
          @extend .list-style;
          &:hover {
            color: theme.$primary-600;
          }
          &:active {
            background-color: theme.$primary-600;
            color: theme.$white;
            .ant-menu-item-icon > svg > path {
              fill: theme.$primary-600;
            }
          }
        }
      }
    }
  }
  .ant-menu-submenu-popup {
    .ant-menu.ant-menu-sub.ant-menu-vertical {
      @extend .menu-style;
      li.ant-menu-item {
        &.ant-menu-item-selected {
          background-color: theme.$primary-600;
          color: theme.$white;
          &:hover {
            color: theme.$white;
          }
        }
        &:hover {
          color: inherit;
        }
      }
    }
  }
  .version-num {
    font-family: fonts.$Source-Sans;
    font-size: 0.875rem;
    color: theme.$primary-600;
    text-align: center;
    margin-top: 0.3rem;
  }
}
