@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

.dot {
  width: 4px;
  height: 4px;
  background-color: #4c4c4c;
  border-radius: 100%;
  display: inline-block;
}

.title {
  font-family: fonts.$Source-Sans;
  font-size: 0.875rem;
  font-weight: 400;
  color: #4c4c4c;
}
.req-password {
  font-family: fonts.$Source-Sans;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 18px;
  color: #ef4d56;
  margin-top: 2px;
}
.password-rules {
  margin-top: 0.5rem;
}
.icon {
  width: 12px;
}
