@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

:global {
  .assessmentType {
    &__form {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      &__card {
        margin: 0.5rem 0.5rem;
        width: calc(50% - 1rem);
        max-height: 130px;
      }

      &__card {
        cursor: pointer;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 16px;
        border: 1px solid #d6d6d6;
        &.active {
          border: 4px solid theme.$black;
        }
        &--icon {
          margin-bottom: 1rem;
        }
        .viwell-containerHeading {
          margin-bottom: 0;
        }
        .ant-card-body {
          padding: 0;
        }
      }
      &__language {
        width: 100%;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        .ant-space-item {
          width: inherit;
        }
      }
      &__footer {
        width: calc(100% + 4rem);
      }
    }
  }
}
