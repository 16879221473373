@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.filter {
  @include flex(space-between, center);
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}

.dashboard-cards {
  margin-top: 1.125rem;

  :global(.ant-col) {
    align-self: stretch;

    :global(.ant-card) {
      height: 100%;

      :global(.ant-card-body) {
        height: 100%;
      }
    }
  }

  :global(.ant-badge-status-dot) {
    width: 1.375rem;
    height: 1.375rem;
  }

  :global(.sm .ant-badge-status-dot) {
    width: 0.875rem;
    height: 0.875rem;
  }
}

.dashboard-row {
  margin-top: 1.5rem;
}

.user-stats {
  text-align: center;

  .text {
    font-weight: 400;
    font-size: 0.75rem;
  }

  .value {
    font-weight: 600;
    font-size: 1 rem;
  }

  :global(.ant-badge-status-text) {
    margin-left: 0;
  }
}

.section-title {
  font-family: fonts.$SF-Pro-Display;
  font-size: 1.125rem;
  font-style: normal;
  line-height: 1.375rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #000000;
}

.sub-title {
  margin-bottom: 1.25rem;
  font-size: 1rem;
  color: #808080;
  font-weight: 400;
}

.stat-header {
  @include flex(space-between, stretch);
}

.stat-title {
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  color: #303030;
  margin-top: 0.75rem;
}

.stat-value {
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem !important;
  line-height: 1.375rem;
  margin-top: 0.75rem;
}

.state-empty {
  @include flex(center, center, column);

  .text {
    color: #adadad;
    margin-bottom: 1.25rem;
    text-align: center;
  }

  .btn {
    @include flex(center, center, column);
    cursor: pointer;
    margin-bottom: 1.25rem;
  }
}

.challenges-chart {
  @include flex(center, normal, column);
}

.habits-chart,
.users-chart,
.checkins-chart {
  @include flex(space-between, normal, column);
  height: 100%;
  margin-top: 40px;

  .org-list-wrap {
    flex-wrap: wrap;
  }

  .checkins-list {
    @include flex(space-between);
    text-align: center;
    min-height: 12.5rem;

    .checkin-container {
      height: 145px;
    }

    .content-container {
      height: 215px;
      display: flex;
      flex-direction: column;
    }

    .content-container-height {
      height: 300px;
    }

    .org-metric-container {
      height: 180px;
    }

    .checkin-text {
      @include font(0.75rem, inherit, #7573e1);
    }

    .clickable {
      cursor: pointer;
    }
  }
}

.checkin-details {
  width: 268px;
  height: 268px;
  .header {
    @include flex(space-between);
    margin-bottom: 0.5rem;
    color: #303030;
    font-family: fonts.$SF-Pro-Display;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
}

.pillars-content-height {
  height: 100%;
}
.infotext-popup-container {
  width: 268px;
  height: auto;
  .header {
    @include flex(space-between);
    margin-bottom: 0.5rem;
    color: #303030;
    font-family: fonts.$SF-Pro-Display;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .info-popup-scroll-container {
    height: auto;
    max-height: 350px;
    overflow-y: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .info-popup-scroll-container::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }
  .info-content-container {
    display: flex;
    margin-bottom: 1rem;
    .info-text {
      margin-left: 5px;
    }
    .popup-info-icon {
      font-size: 1rem;
    }
  }
}

.content-container-height {
  height: 310px;
}

.list-content-height {
  height: 240px;
}

.content-nodata-container-height {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
}

.dept-details {
  width: 268px;
  height: auto;
  max-height: 352px;
  .header {
    @include flex(space-between);
    margin-bottom: 0.5rem;
    color: #303030;
    font-family: fonts.$SF-Pro-Display;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .dept-scroll-container {
    height: auto;
    max-height: 300px;
    overflow-y: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .dept-scroll-container::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }
  .dept-details-container {
    display: flex;
    padding-right: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-top: 16px;
    align-self: stretch;
    .depts-main-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .department-container {
        display: flex;
        width: 186px;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.badge-caption {
  text-align: center;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 1.125rem;
  margin-top: 0.625rem;
}

.no-data-container {
  @include flex(center, center);
  margin-top: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .no-data {
    text-align: center;
    flex-direction: column;
    align-items: center;

    .no-data-img {
      max-width: 100px;
    }

    .no-data-title {
      @include font(1rem, 600, #303030);
      color: #303030;
      text-align: center;
      font-family: fonts.$SF-Pro-Display;
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
    }

    .no-data-sub-title {
      @include font(0.875rem, 400, #adadad);
      margin-top: 0.5rem;
    }
  }
}

.edit-dashboards {
  :global(.ant-checkbox-group-item) {
    display: flex;
  }
}

.section-card {
  border-radius: 0.5rem;
  // height: 11.125rem;
}

.no-data-btn {
  border-radius: 0.5rem;
  border: 0.063rem solid #121212;
  color: #121212;
  font-weight: 500;
  margin-top: 0.313rem;
}

.sections-header {
  display: flex;
  margin: 16px 0 30px 0;
  flex-wrap: wrap;

  .section-item {
    margin-right: 1rem;
    text-align: center;
    font-family: fonts.$SF-Pro-Display;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    line-height: 1.25rem; /* 142.857% */
  }

  .section-item-selected {
    color: #121212;
    font-weight: 700;
  }
}

.assessment-container {
  height: 305px;
  display: flex;
  flex-direction: column;
}

.section-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -0.313rem;
}

.info-icon {
  font-size: 1.25rem;
  color: #808080;
}

.org-menu {
  margin-left: 1rem;
}

.add-value-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.metrices-values-container {
  margin-top: 1rem;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #d6d6d6;
}

.item-header {
  color: #303030;
  font-family: fonts.$Source-Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
}

.percentange-text {
  color: #5555a4;
  /* Headline/h1 */
  font-family: fonts.$SF-Pro-Display;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.875rem; /* 109.524% */
  margin-right: 0.5rem;
}

.percentange-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pillars-insights-margin-top {
  margin-top: 0;
}

.checkin-btn {
  border-radius: 0.938rem;
  width: 8rem;
  background: #5555a4;
  color: #fff;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.row-flex {
  flex: 1;
}

.checkin-insights-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.checkin-insights,
.content-item-text {
  color: #303030;
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.pillars-content-text {
  color: #303030;
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}

.pillars-header-text {
  color: #303030;
  text-align: center;
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
}

.checkin-past-days {
  color: #808080;
  text-align: center;
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.past-value-comparison {
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.past-value-container,
.org-menu-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.past-value-up {
  color: #49bf26;
}

.past-value-down {
  color: #ef4d56;
}

.checkin-bg {
  // height: 134px;
  // width: 134px;
  position: absolute;
  right: -5;
  bottom: 0;
  background-repeat: no-repeat;
}

.tooltip-container {
  border-radius: 8px;
  border: 1px solid #5555a4;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(2px);
  padding: 0.313rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tooltip-value {
  color: #5555a4;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  margin: 0.2rem;
}

.barchart-container {
  width: 360px;
  height: 200px;
  margin-left: -1.563rem;
}

.score-barchart-container {
  // width: 115%;
  height: 225px;
  margin-top: 16px;
  margin-left: -1.563rem;
  margin-right: -3rem;
}

.metrices-linechart-container {
  height: 130px;
  margin-top: 16px;
  margin-left: -2.3rem;
  margin-right: -2.9rem;
}

.pillars-linechart-container {
  height: 230px;
  margin-top: 16px;
  margin-left: -2.5rem;
  margin-right: -3.5rem;
}

.barchart-label {
  text-align: center;
  color: #808080;
  font-size: 0.75rem;
  font-family: fonts.$SF-Pro-Display;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 0.2rem;
}

.metrices-linechart-label {
  text-align: center;
  color: #808080;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: fonts.$SF-Pro-Display;
}

.monthly-label {
  font-size: 0.4rem;
}

.margin-left-5 {
  margin-left: 0.313rem;
}

.margin-top-16 {
  margin-top: 1rem;
}

.margin-right-8 {
  margin-right: 0.5rem;
}

.y-axis-value {
  color: #808080;
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.barchart-border-left {
  height: 100%;
  border-left-width: 2px;
  border-left-style: dashed;
  border-left-color: #dedada;
}

.filter-container {
  display: flex;
}

.date-range-padding {
  padding: 20px;
}

.active-filter-container {
  display: flex;
  padding: 4px 8px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 4px;
  background: #fff;
  height: 24px;
  margin-top: 4px;
}

.pillar-insights-margin {
  margin: 0 2px;
}

.active-filter-text {
  color: #000000;
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.card-height-for-data {
  height: 330px;
}

.loading-container {
  display: flex;
  justify-content: center;
}

.org-low-score-insight {
  display: flex;
  justify-content: center;
  color: #303030;
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.75rem;
  font-style: normal;
  margin-top: 10px;
  font-weight: 400;
  line-height: 16px;
}

.org-metric-insight {
  font-size: 0.625rem;
  line-height: normal;
  color: #808080;
  font-family: fonts.$SF-Pro-Display;
  font-style: normal;
  font-weight: 400;
}

.pie-chart-container {
  width: 212px;
  height: 212px;
}

.category-pie-chart-container {
  width: 250px;
  height: 250px;
}

.challenges-pie-chart-container {
  width: 250px;
  height: 250px;
}

.viwell-assessment-container {
  display: flex;
  margin-top: 16px;
  height: 257px;
  align-items: center;
  justify-content: space-between;
}

.total-challenges-container {
  display: flex;
  margin-top: 16px;
  height: 210px;
  align-items: center;
  justify-content: space-between;
}

.total-assessments-container {
  width: 290px;
}

.completed-assessments-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .completed-assessments-box {
    display: flex;
    padding: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #d6d6d6;
    width: 141px;
    .box-main-text {
      color: #303030;
      font-family: fonts.$SF-Pro-Display;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .box-sub-text {
      color: #303030;
      font-family: fonts.$SF-Pro-Display;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
}

.divider-color {
  background-color: #d6d6d6;
  margin: 0;
}

.departments-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 190px;
  margin-top: 16px;
  .department-container {
    display: flex;
    height: 36px;
    width: 50%;
    margin-bottom: 24px;
    .department-bar {
      border-radius: 2px;
      width: 8px;
      margin-right: 8px;
    }
    .department-text-container {
      width: 100%;
      flex-direction: column;
      display: flex;
      margin-right: 8px;
    }
    .department-percentage {
      display: flex;
      justify-content: space-between;
    }
  }
}

.challenges-content-height {
  height: 120px;
}

.categories-container {
  display: flex;
  width: 52%;
  flex-direction: column;
  height: 250px;
  .category-margin {
    margin-bottom: 0.3rem;
  }
  .category-details-container {
    display: flex;
    height: 32px;
    margin-bottom: 0.3rem;
    .pillar-content-container {
      display: flex;
      width: 55%;
      align-items: center;
      justify-content: space-between;
    }
    .pillar-left-container {
      width: 40%;
      display: flex;
      align-items: center;
    }
    .category-bar {
      border-radius: 2px;
      width: 8px;
      margin-right: 8px;
    }

    .category-text-container {
      width: 100%;
      flex-direction: column;
      display: flex;
      margin-right: 8px;
    }
    .category-percentage {
      display: flex;
      justify-content: space-between;
    }
  }
}

.department-title,
.content-index,
.category-title {
  color: #808080;
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.department-desc-text,
.category-desc-text {
  color: #303030;
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.clear-all-filters {
  display: flex;
  padding: 4px 8px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 4px;
  height: 24px;
  margin-top: 4px;

  color: #000000cc;
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.content-item-container {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  border-radius: 2px;
  .content-text-container {
    display: flex;
    .content-index-width {
      width: 1rem;
    }
  }
  .content-container-spacing {
    padding: 8px 0;
  }
  .content-spacing {
    margin: 0 4px;
  }
}

.pillar-list-container {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .pillar-content-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    .pillar-content-container {
      display: flex;
      width: 55%;
      align-items: center;
      justify-content: space-between;
    }
    .pillar-left-container {
      width: 45%;
      display: flex;
      align-items: center;
    }
  }
  .pillar-content-height {
    height: 210px;
    display: flex;
    flex-direction: column;
  }
  .content-height {
    height: 43px;
  }
}

.bg-color {
  background: #fafafa;
}
