.streak-header {
  margin-bottom: 0.5rem;
}
.streak-content {
  max-width: 500px;
  width: 100%;
  .streak-item {
    display: grid;
    grid-template-columns: 1fr auto;
    row-gap: 1.5rem;
    column-gap: 10px;
    .delete-btn {
      margin-top: 2rem;
    }
  }
}
.flex-header {
  display: flex;
  column-gap: 12px;
}
