/* src/fonts/almarai-fonts.css */
@font-face {
    font-family: "Almarai";
    font-weight: 300;
    src: url("./Almarai-Light.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Almarai";
    font-weight: 400;
    src: url("./Almarai-Regular.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Almarai";
    font-weight: 700;
    src: url("./Almarai-Bold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Almarai";
    font-weight: 800;
    src: url("./Almarai-ExtraBold.ttf") format("truetype");
  }
  