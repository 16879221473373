@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.users {
  .header {
    @include flex(space-between, center);
    margin-bottom: 1.25rem;
    .title {
      font-family: fonts.$Source-Sans;
      font-weight: bold;
      font-size: 1.25rem;
      margin-bottom: 0px;
    }
  }
}

:global {
  .filter-tree-select {
    .ant-select-selector {
      min-height: 2.65rem !important;
    }
  }
}

.filter-select {
  margin-bottom: 0 !important;
}
