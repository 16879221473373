@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.habit-header {
  @include flex(space-between, center);
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  .habit-title {
    font-family: fonts.$Source-Sans;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
}
.content-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  .flex-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
  }
}

.habit-heading {
  font-family: fonts.$Source-Sans;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
