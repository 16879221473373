@import "/src/assets/css/mixins/mixins.scss";
.categories {
  .header {
    @include flex(space-between, center);
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    color: #2e2c34;
    margin-bottom: 0px;
  }
  .main-categories-table {
    margin-top: 2.5rem;
  }
}
