@tailwind base;
@tailwind components;
@tailwind utilities;
.react-tel-input .selected-flag {
  background-color: transparent !important;
}
img {
  display: initial;
}
svg {
  vertical-align: baseline;
}
