@use "/src/assets/css/partials/fonts";
@import "/src/assets/css/mixins/mixins.scss";
.partner-details {
  @include flex(space-between, center);
  margin-top: 8px;
  margin-bottom: 24px;
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0px;
  }
}

.partner-rewards {
  margin-top: 2rem;
}
