@use "/src/assets/css/partials/fonts";
@import "/src/assets/css/mixins/mixins.scss";
.offer {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}

.ml-3 {
  margin-left: 0.5rem;
}

.mr-3 {
  margin-right: 0.5rem;
}
