@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
:global {
  .ant-upload.ant-upload-drag.custom-dragger {
    & .ant-upload {
      padding: 0px;
    }
    .ant-upload-btn {
      display: inline-block;
    }
    .ant-upload-drag-container {
      display: block;
      height: 100%;
      & div.ant-space {
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
    border-radius: 8px;
    padding: 0px;
    height: 270px;
  }
  .custom-spinner.ant-spin {
    .ant-spin-text {
      font-family: fonts.$Inter;
      font-weight: 400;
      font-size: 0.875rem;
      color: theme.$primary-600;
    }
  }
  .content-text {
    font-family: fonts.$Inter;
    font-weight: 500;
    font-size: 0.875rem;
  }
  .preview-img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

.gallery-images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 24px;
  margin-bottom: 1.5rem;
}

.image-container {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  .gallery-image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

.image-container::before,
.image-container::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 70px; /* Adjust the width of the border/layer as needed */
  background-color: #d9d9d9;
  opacity: 0.7;
}

.image-container::before {
  border-radius: 8px 0px 0px 8px;
  left: 0;
}

.image-container::after {
  border-radius: 0px 8px 8px 0px;
  right: 0;
}
.gallery-img-remove {
  font-size: 1.8rem;
  opacity: 0.4;
  position: absolute;
  top: 11px;
  right: 22px;
  z-index: 5;
  cursor: pointer;
}

.img-loading {
  display: flex;
  align-items: center;
  height: 270px;
  justify-content: center;
  position: relative;
  .loading-content {
    padding: 16px;
    .progress {
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 16px;
    }
  }
}

.gallery-view {
  border: 1px dashed #d9d9d9;
  transition: border-color 0.3s;
  border-radius: 8px;
  &:hover {
    border-color: #40a9ff;
  }
}
