@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.admins {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}
.confirm-content {
  padding: 1.5rem 0px;
}

.custom-label {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 6px;
}

:global {
  .ant-input-group {
    .PhoneInput {
      .PhoneInputInput {
        width: 100%;
        outline: none;
        height: 3rem;
        background: #fff;
        border: 1px solid rgba(128, 128, 128, 0.16);
        border-radius: 0.75rem;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 400;
        font-size: 0.875rem;
      }

      .flag-dropdown {
        border-radius: 0.75rem 0 0 0.75rem;

        &.selected-flag {
          border-radius: 0.75rem 0 0 0;
        }

        &.open {
          border-radius: 0.75rem 0 0 0;

          .selected-flag {
            border-radius: 0.75rem 0 0 0.75rem;
          }
        }
      }

      .selected-flag {
        border-radius: 0.75rem 0 0 0.75rem;
      }
    }
  }

  /* style the container of the React Phone Number Input component */
}
