@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

.form-control {
  margin-bottom: 1.5rem;
}

.label {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 6px;
}

.text-danger {
  color: red;
}
