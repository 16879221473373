@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";

.add-content-header {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}
.name-desc {
  font-family: fonts.$Source-Sans;
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
}
.multi-lang-box {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.5rem;
}
.flex-div {
  @include flex(space-between, center);
}
.add-btn {
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
}
.content-card {
  margin-bottom: 2rem;
}
.btn-div {
  @include flex(space-between, center);
}
.remove-btn {
  margin-bottom: 1.5rem;
  cursor: pointer;
  font-weight: 600;
}

.help-text {
  h4 {
    font-weight: bold;
  }

  p {
    font-size: 0.68rem;
    margin-bottom: 0.125rem;
  }

  p,
  h4 {
    color: white;
  }
}

.label-with-help {
  @include flex(flex-start, baseline);
  gap: 0.5rem;
}

.divider {
  @include flex(end, flex-start);
  gap: 8px;

  :global(.remove-btn) {
    margin-left: 1.25rem;
  }

  & > div {
    flex-grow: 1;
  }
}

.flex-end {
  @include flex(end, center);
  gap: 0.5rem;

  .add-btn {
    margin-top: 1.25rem;
  }
}

.subtitles {
  background-color: rgb(240 243 247 / 31%);
  margin-bottom: 1.5rem;
  padding: 1.5rem;
}
