@use '/src/assets/css/partials/fonts';
@use '/src/assets/css/partials/theme';
@import "/src/assets/css/mixins/mixins.scss";

.company {
  .header {
    @include flex(space-between, center);
    margin-bottom: 1.25rem;
  }
  .title {
    font-family: fonts.$Source-Sans;
    color: theme.$black;
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
  .company-list{
    margin-top: 1.25rem;
  }
}
