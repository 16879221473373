.option-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}
.emoji-flex {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}
.score-flex {
  display: grid;
  grid-template-columns: 1fr auto auto;
  column-gap: 1rem;
  row-gap: 1rem;
}
.option-filter {
  @extend .score-flex;
  grid-template-columns: 50px;
}
.title-flex {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  align-items: center;
}
.sub-text {
  font-weight: 400;
  font-size: 12px;
  color: #808080;
}
