@use "/src/assets/css/partials/fonts";
@import "/src/assets/css/mixins/mixins.scss";
.units {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}

.add-translation {
  margin-bottom: 1.5rem;
}

.langs {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1rem;
}

.delete-btn {
  margin-top: 1.9rem;
}
