.form-control {
  margin-bottom: 1.5rem;
}
.btn-div {
  width: fit-content;
  margin-left: auto;
}

.confirm-content {
  padding: 1.5rem 0px;
}
