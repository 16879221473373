.slider-header {
  display: flex;
  column-gap: 1.5rem;
  align-items: baseline;
}
.rating-option {
  display: grid;
  grid-template-columns: 50px 100px auto 1fr auto 50px;
  row-gap: 1rem;
  column-gap: 1rem;
  align-items: baseline;
}
.rating-header {
  @extend .rating-option;
  grid-template-columns: 100px 100px 1fr auto 50px;
}
.emojiandtext {
  display: grid;
  grid-template-columns: 50px 250px;
  row-gap: 1rem;
  column-gap: 1rem;
  align-items: baseline;
}
.inner-flex {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 1rem;
  column-gap: 1rem;
  align-items: baseline;
}
.option-filter {
  @extend .rating-option;
  grid-template-columns: 100px 1fr auto auto 50px;
}
.sub-question {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.timeLable {
  display: grid;
  grid-template-columns: auto 75px;
  row-gap: 1rem;
  column-gap: 1rem;
  align-items: baseline;
}
.range-error {
  color: #fc3400;
  text-align: left;
  margin-right: 60px;
  margin: 10px 0px 20px 20px;
}
