@use "/src/assets/css/partials/theme";
@use "/src/assets/css/partials/fonts";
@import "/src/assets/css/mixins/mixins.scss";
:global {
  .viwell-header.ant-layout-header {
    background: theme.$white;
    height: 4.875rem;
    line-height: 4.875rem;
    box-shadow: inset 0px -1px 0px #e2e8f0;
  }
  .profile-menu.ant-btn {
    color: theme.$primary-600;
    font-weight: 700;
    font-size: 16px;
  }
  .profile-menu.ant-dropdown-menu {
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    top: 12px;
    .ant-dropdown-menu-item {
      font-family: fonts.$Inter;
      font-weight: 500;
      font-size: 0.875rem;
      color: theme.$primary-600;
    }
  }
  .form-control {
    margin-bottom: 1.5rem;
  }
}
.admin-header {
  @include flex(flex-end, center);
}
