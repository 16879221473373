@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

.form-control {
  margin-bottom: 1.5rem;
}

.color-picker {
  font-weight: 600;
  font-weight: 1.5rem;
}

.label {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 6px;
}

:global {
  .custom-viwell-picker {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &--fastField {
      margin-bottom: 0rem;
      margin-right: 1rem;
      width: 400px;
    }
    &--swatch {
      width: 3rem;
      height: 3rem;
      border-radius: 8px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1),
        inset 0 0 0 1px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
    &--mt-2 {
      margin-top: 2rem;
    }
  }
  .popover {
    &--header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      .label {
        margin-bottom: 0;
      }
    }
    .react-colorful {
      width: auto;
    }
    .react-colorful__saturation {
      border-radius: 8px;
    }
    .react-colorful__last-control {
      border-radius: 8px;
      height: 8px;
      margin-top: 10px;
      .react-colorful__pointer {
        width: 8px;
        height: 8px;
      }
    }
    &--form {
      display: flex;
      margin-top: 1rem;
      align-items: center;

      .custom-viwell-picker--swatch {
        width: 3rem;
        height: 3rem;
      }
      .ant-space-item:nth-child(2) {
        div {
          margin-bottom: 0rem;
        }
      }
    }
  }
}
