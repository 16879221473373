@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

.label {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 6px;
}

.subLabel {
  display: block;
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 6px;
}
:global {
  .ant-radio-group {
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .ant-radio-inner {
    width: 20px;
    height: 20px;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: theme.$basic-900 !important;
    border-width: 6px;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner {
    border-color: theme.$basic-900 !important;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    border-width: 6px;
  }
  .ant-radio-inner::after {
    background-color: transparent !important;
  }
  .ant-radio-checked::after {
    border: 1px solid theme.$basic-900 !important;
    border-width: 6px;
  }
}
