@use "/src/assets/css/partials/theme";
@use "/src/assets/css/partials/fonts";
:global {
  .OnBoarding-container {
    margin-bottom: 1.5rem;
    .custom-upload
      .ant-upload-list-picture-card
      .ant-upload-list-item
      .ant-upload-list-item-info {
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
    .custom-upload .ant-upload.ant-upload-select-picture-card,
    .ant-upload-list-picture-card-container,
    .custom-upload .ant-upload-list-picture-card .ant-upload-list-item {
      border-radius: 8px;
      width: 160px;
      height: 160px;

      .ant-upload {
        .ant-space {
          align-items: center;
          padding: 1rem;
          .ant-space-item:nth-child(1) {
            width: 24px;
            height: 24px;
            margin-bottom: 1rem;
            img {
              border-radius: 0px;
            }
          }
          .ant-space-item:nth-child(2) {
            font-family: fonts.$SF-Pro-Display;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: center;
            max-width: 109px;
          }
        }
      }
    }
  }
}
