@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

:global {
  .btn-style {
    border-radius: 0.5rem;
    font-family: fonts.$Source-Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    height: auto;
    padding: 0.625rem 1.125rem;

    &:hover {
      opacity: 0.9;
    }

    // &[disabled],
    // &[disabled]:hover,
    // &[disabled]:focus,
    // &[disabled]:active {
    //   color: rgba(232, 230, 227, 0.25);
    //   border-color: rgb(59, 64, 66);
    //   background-image: initial;
    //   background-color: rgb(30, 32, 33);
    //   text-shadow: none;
    //   box-shadow: none;
    // }
  }

  .primary-btn.ant-btn {
    @extend .btn-style;
    background: theme.$primary-600;
    color: theme.$white;
    border-color: theme.$primary-600;

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
    }
  }

  .light-btn.ant-btn {
    @extend .btn-style;
    background: theme.$white;
    color: theme.$black;
    border-color: theme.$white;
  }
  .light-btn.ant-btn.icon {
    display: flex;
    // justify-content: center;
    align-items: center;
  }

  .light-btn.ant-btn-icon-only {
    padding: 2.4px 0;
    vertical-align: -3px;
  }

  .link-btn.ant-btn.ant-btn-link {
    font-size: 1.15rem;
    color: theme.$primary-600;
    height: auto;
    padding: 0;
  }
  .link-text-btn {
    @extend .btn-style;
    border: none;
    background: none;
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
      border: none !important;
      background: none !important;
      box-shadow: none !important;
      color: theme.$black;
    }
  }

  .btn-light.ant-btn {
    @extend .btn-style;
    background-color: theme.$basic-200;
    border-color: theme.$basic-200;

    &:hover {
      color: initial;
    }
  }
  .remove-btn.ant-btn {
    background: #f4f4f4;
    border-radius: 12px;
    padding: 0.5rem 0.875rem;
    height: auto;
    color: theme.$primary-600;
    font-size: 1.25rem;
  }
  .cross-btn.ant-btn {
    @extend .btn-style;
    height: 3rem;
    background-color: #f4f4f4;
    border-color: #f4f4f4;
    padding: 0 0.875rem;
  }
  .add-option-btn.ant-btn {
    height: auto;
    color: theme.$primary-600;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0;
  }
  .emoji-btn.ant-btn {
    @extend .btn-style;
    height: 3rem;
    padding: 0px 0.875rem;
    font-size: 1.5rem;
  }
  .btn-sm {
    padding: 5px 8px !important;
    height: fit-content !important;
    margin-right: 0.25rem !important;
  }
  .basic-btn.ant-btn {
    @extend .btn-style;
    background-color: #f4f4f4;
    border-color: #f4f4f4;
    &:hover,
    &:active,
    &:focus {
      color: theme.$black;
    }
  }
}
