@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.specialist {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}

.flex-div {
  @include flex(space-between, center);
}

.form-control {
  margin-bottom: 1.5rem;
}

.add-btn {
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
}
.btn-style {
  font-family: fonts.$Inter;
  font-weight: 400;
  font-size: 0.875rem;
  cursor: pointer;
}
.remove-btn {
  @extend .btn-style;
  color: theme.$danger;
  margin-top: -0.5rem;
  display: block;
}
