@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";

.add-content-header {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}
.add-option {
  font-family: fonts.$Source-Sans;
  font-size: 1rem;
}
.viwell-checkbox {
  font-family: fonts.$Source-Sans;
  font-size: 1rem;
  display: flex;
  align-items: center;
}
.delete-div {
  display: flex;
  align-items: center;
}
.delete-icon {
  background-color: #f4f4f4;
  color: #231f20;
  border-style: none;
}
.name-desc {
  font-family: fonts.$SF-Pro-Display;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 1.5rem;
  line-height: 20px;
}
.multi-lang-box {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.5rem;
}
.flex-div {
  @include flex(space-between, center);
}
.add-btn {
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
}
.content-section {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.btn-div {
  @include flex(space-between, center);
  margin-bottom: 20px;
}
.activity-card {
  margin-bottom: 1rem;
}
.remove-btn {
  margin-bottom: 1.5rem;
  cursor: pointer;
  font-weight: 600;
}

.help-text {
  h4 {
    font-weight: bold;
  }

  p {
    font-size: 0.9rem;
    margin-bottom: 0.125rem;
  }

  p,
  h4 {
    color: white;
  }
}

.label-with-help {
  @include flex(flex-start, baseline);
  gap: 0.5rem;
}

.divider {
  @include flex(end, flex-start);
  gap: 8px;

  :global(.remove-btn) {
    margin-left: 1.25rem;
  }

  & > div {
    flex-grow: 1;
  }
}

.flex-end {
  @include flex(end, center);
  gap: 0.5rem;

  .add-btn {
    margin-top: 1.25rem;
  }
}

.subtitles {
  background-color: rgb(240 243 247 / 31%);
  margin-bottom: 1.5rem;
  padding: 1.5rem;
}

.event-img {
  width: 480px;
  margin-bottom: 2rem;
}

.gallery-images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 24px;
  margin-bottom: 1.5rem;
}

.header {
  font-family: fonts.$Source-Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 0;
}

.team-content {
  max-width: 550px;
  width: 100%;
  .team-item {
    display: grid;
    grid-template-columns: 1fr auto auto;
    row-gap: 1.5rem;
    column-gap: 10px;
    .delete-btn {
      margin-top: 2rem;
    }
  }
}
