@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
:global {
  .custom-upload {
    .ant-upload.ant-upload-select-picture-card {
      border-radius: 100%;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
      border-radius: 100%;
      .ant-upload-list-item-info {
        border-radius: 100%;
      }
    }
    .ant-upload.ant-upload-select-picture-card:hover {
      .img-url {
        .upload-action {
          background-color: rgba(0, 0, 0, 0.5);
          .action {
            visibility: visible;
          }
        }
      }
    }
    &--intro {
      margin-bottom: 1rem;
    }
  }

  .img-border {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 70px;
    background-color: #d9d9d9;
    opacity: 0.7;
  }
  .img-container-left {
    @extend .img-border;
    left: 0;
  }

  .img-container-right {
    @extend .img-border;
    right: 0;
  }

  .img-close-circle {
    font-size: 1.8rem;
    opacity: 0.7;
    margin-top: 10px;
  }

  .img-fluid {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
  .img-fluid-preview {
    @extend .img-fluid;
    border-radius: unset;
  }
  .upload-img {
    width: 55%;
    height: 100%;
    object-fit: cover;
  }
  .img-full-preview {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .img-url {
    padding: 8px;
    width: 102px;
    height: 102px;
    position: relative;
    .upload-action {
      transition: all 0.3s;
      top: 8px;
      position: absolute;
      height: 86px;
      width: 86px;
      overflow: hidden;
      border-radius: 100%;
      .action {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        z-index: 35895;
        font-weight: 600;
        gap: 5px;
        font-size: 16px;
        color: white;
        visibility: hidden;
      }
    }
    input[type="file"] {
      display: none !important;
    }
  }
  .file-text {
    font-family: fonts.$Inter;
    font-weight: 500;
    font-size: 0.785rem;
  }
}
.form-control {
  margin-bottom: 1.5rem;
}
