.divider {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
}

.doc-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  .header-title {
    font-weight: 700;
    font-size: 1.25rem;
  }
}
