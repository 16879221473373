@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";

.title {
  font-family: fonts.$Source-Sans;
  color: theme.$default;
  font-weight: 700;
  font-size: 1.875rem;
  text-align: center;
  margin-bottom: 2.5rem;
}
.description {
  font-family: fonts.$Source-Sans;
  font-weight: 600;
  font-size: 1rem;
  color: #ADADAD;
  text-align: center;
  margin-bottom: 2.5rem;

}
.btn-div {
  max-width: 265px;
  margin: 0 auto;
}
