@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
:global {
  .input-style {
    height: 3rem;
    background: theme.$white;
    border: 1px solid rgba(128, 128, 128, 0.16);
    border-radius: 0.75rem;
    font-family: fonts.$Source-Sans;
    font-weight: 400;
    font-size: 0.875rem;
  }
  .phone-input.form-control {
    width: 100%;
    @extend .input-style;
    &:focus {
      box-shadow: none;
    }
    + .flag-dropdown {
      border-radius: 0.75rem 0px 0px 0.75rem;
      .selected-flag {
        border-radius: 0.75rem 0px 0px 0.75rem;
      }
    }
  }
}
