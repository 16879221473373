@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
:global {
  .content-card.ant-card {
    background: theme.$white;
    box-shadow: 0px 8px 12px 0px #64748b29;
    border-radius: 0.75rem;
    .ant-card-body {
      padding: 1.5rem;
    }
  }
  .collection-card.ant-card {
    background: theme.$white;
    border-radius: 0.5rem;
    .ant-card-body {
      padding: 1.5rem;
    }
  }
  .notification-card.ant-card {
    background: theme.$white;
    border-radius: 0.5rem;
    .ant-card-body {
      padding: 1rem 0.5rem;
    }
  }
  .org-metrics-card.ant-card {
    background: theme.$white;
    border-radius: 0.5rem;
    .ant-card-body {
      padding: 1.5rem 1rem;
    }
  }
}
