@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

.form-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 2rem;
  margin-bottom: 2rem;
}
.desc-container {
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 1.5rem;
}
.detail {
  display: grid;
  grid-template-columns: 130px 1fr;
  column-gap: 1rem;
  margin-bottom: 1.5rem;
}
.label {
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.875rem;
  font-weight: 400;
  color: #4c4c4c;
}
.description {
  font-family: fonts.$SF-Pro-Display;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0px;
}
.status-detail {
  display: grid;
  grid-template-columns: 130px auto;
  column-gap: 1rem;
}

.active-user {
  color: theme.$success;
}
.deactivated-user {
  color: theme.$gray;
}
