@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
.forgot-password {
  text-align: center;
}
.btn-div {
  max-width: 270px;
  margin: 0 auto;
  margin-top: 1.5rem;
}
.forgot-link {
  font-family: fonts.$Source-Sans;
  font-weight: 700;
  font-size: 1.125rem;
  color: theme.$default;
  &:hover{
    color: theme.$default;
    opacity: 0.9;
  }
}
