@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";

.desc-container {
  border: 1px solid theme.$basic-500;
  border-radius: 4px;
  padding: 0.25rem 1rem;
}

.header {
  @include flex(space-between, center);
  margin-bottom: 0.5rem;
  .title {
    font-weight: 600;
    font-size: 0.875rem;
    font-family: fonts.$Source-Sans;
  }
  .see-btn {
    @extend .title;
    cursor: pointer;
  }
}

.item-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .item-title {
    margin-left: 1rem;
  }
  .sub-title {
    font-weight: 600;
    font-size: 0.875rem;
    font-family: fonts.$Source-Sans;
    color: #303030;
  }
  .item-id {
    padding: 0px 0.5rem;
  }
  .users {
    text-align: right;
    .count {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1rem;
      color: #121212;
      text-align: right;
    }
  }
}
