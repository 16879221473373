@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

.subLabel {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 6px;
}

.form-control {
  margin-bottom: 1.25rem;
}
