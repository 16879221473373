@use "/src/assets/css/partials/theme";

:global {
  .viwell-dashboard.ant-layout {
    min-height: 100vh;
    background-color: theme.$app-bg;
  }

  .viwell-content.ant-layout-content {
    padding: 2rem 2rem 10rem 2rem;
    margin-bottom: 40px;
    background-color: whitesmoke;

  }
}
