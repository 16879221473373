@use "/src/assets/css/partials/theme";
@use "/src/assets/css/partials/fonts";
@import "/src/assets/css/mixins/mixins.scss";
.bg {
  min-height: 100vh;
  background: url("../../../assets/images/auth-bg.png"), #f5f5f5;
  background-size: cover;
  background-repeat: no-repeat;
}
.auth-layout {
  @extend .bg;
  position: relative;

  .viwell-logo {
    background: #000000;
    border-radius: 1rem;
    padding: 1.56rem 1.25rem;
    width: fit-content;
    position: absolute;
    top: 34px;
    left: 35px;
  }
  .auth-card {
    background: theme.$white;
    border-radius: 40px;
    max-width: 550px;
    padding: 5rem 3.5rem;
    margin: 0 auto;
  }
}
.sign-in {
  @extend .bg;
  .content {
    max-width: 430px;
    margin: 0 auto;
    .viwell-logo {
      background: #000000;
      border-radius: 1rem;
      padding: 2rem 1.5rem;
      width: fit-content;
      margin: 0 auto;
    }
    .title {
      font-family: fonts.$Source-Sans;
      font-weight: 700;
      font-size: 1.875rem;
      margin-top: 1.5rem;
      margin-bottom: 2.75rem;
      text-align: center;
    }
    .sub-title {
      font-family: fonts.$Source-Sans;
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 2.5rem;
      color: #adadad;
      text-align: center;
    }
  }
}
