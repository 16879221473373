.metric-header {
  display: grid;
  grid-template-columns: 200px 200px min-content;
  column-gap: 1rem;
  margin-bottom: 2.5rem;
}
.metric-row {
  display: grid;
  grid-template-columns: 160px 1fr;
  align-items: center;
  column-gap: 6px;
  margin-bottom: 1.5rem;
}

.sub-flex {
  display: flex;
  column-gap: 6px;
  overflow-x: auto;
}
.metric-option {
  width: calc(200px + 1rem);
  min-width: calc(200px + 1rem);
}

.sub-card {
  // width: 200px;
  border: 1px solid #ebebeb;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.inner-sub {
  width: 200px;
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 6px;
  column-gap: 6px;
}

.tags-12 {
  width: 100%;
}
