@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

.customSwitch {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 1rem;
}
.reverse-order {
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.customSwitch--label {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 0px;
}
