@use "/src/assets/css/partials/fonts";
.permissions {
  .title {
    font-family: fonts.$Source-Sans;
    color: #000000;
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}
.btn-div {
  margin-top: 2rem;
  width: fit-content;
  margin-left: auto;
}
.form-control {
  margin-bottom: 1.5rem;
}

.mb {
  margin-bottom: 0.8rem;
}

.error {
  color: #fc3400;
  margin: 1rem 0rem;
}
