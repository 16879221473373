.question-container {
  border-radius: 8px;
  // background-color: #fff !important;
  overflow: hidden !important;
  margin-bottom: 1.5rem;
}

.sub-question {
  display: flex;
  // justify-content: flex-start;
  justify-content: center;
  // justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
:global {
  // .ant-collapse-header .ant-collapse-icon-collapsible-only {
  //   display: flex;
  //   align-items: center;
  // }

  .sub-question {
    .ant-btn {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .primary-btn {
    padding: 0 0.875rem;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
  }
  .question-container--header {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    .ant-space-item:nth-child(1) {
      flex-grow: 1;
    }
    .ant-space-item:nth-child(2) {
      flex-grow: 2;
    }

    .ant-space-item:nth-child(3) {
      width: 100px;
    }
    .ant-space-item {
      div {
        margin-bottom: 0rem;
      }
    }
  }
}
