@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";

.add-content-header {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}
.name-desc {
  font-family: fonts.$Source-Sans;
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
}
.multi-lang-box {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.5rem;
}
.flex-div {
  @include flex(space-between, center);
}
.add-btn {
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
}
.content-card {
  margin-bottom: 2rem;
}

.remove-btn {
  margin-bottom: 1.5rem;
  margin-top: -1.5rem;
}

.form-control {
  margin-bottom: 1.5rem;
}

.user-status {
  @include flex(flex-start, center);
  gap: 0.8rem;
}

.btn-div {
  @include flex(space-between, center);
  margin-top: 2rem;
  gap: 1rem;
}

.tag {
  cursor: pointer;
}
