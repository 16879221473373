@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.activity {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
}
.activity-style {
  font-family: fonts.$SF-Pro-Display;
  font-weight: 700;
}
.title {
  @extend .activity-style;
  font-size: 1.25rem;
  margin-bottom: 0px;
}
.activity-title {
  @extend .activity-style;
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
}
.activity-img {
  margin-bottom: 1.5rem;
  img {
    max-width: 100%;
    object-fit: cover;
    border-radius: 1rem;
    max-height: 350px;
  }
}
.description {
  margin-bottom: 0.5rem;
  h2.desc {
    font-family: fonts.$Source-Sans;
    font-weight: 700;
    font-size: 1rem;
  }
  p {
    font-family: fonts.$Source-Sans;
    font-weight: 500;
    font-size: 1.15rem;
    margin-bottom: 0px;
  }
}

.data-table-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 5px 0;
}

.data-table-card {
  height: 330px;
  width: 100%;
  border-style: solid;
  border-color: gray;
  border-radius: 5px;
  border-width: 0.2px;
  padding: 20px;
}
.modal-data {
  height: 660px;
}

.card-left {
  display: flex;
  align-items: center;
}

.card-right {
  display: flex;
  flex-direction: column;
  line-height: 0.2rem;
  text-align: right;
}

.data-table-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.search-input {
  background: white;
  padding: 0.2rem;
  font-size: 2rem !important;
  input {
    font-size: 1rem;
    font-weight: 400;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  span {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
}

.participating-title-container {
  margin-left: 16px;
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.participating-title {
  @extend .activity-style;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  color: #121212;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}

.title-semibold {
  @extend .participating-title;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.participating-desc {
  @extend .activity-style;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  /* Basic/1100 */

  color: #121212;

  opacity: 0.7;
}

.bg-container {
  height: 270px;
  position: relative;
  margin-bottom: 50px;
  background: #797979;
  border-style: solid;
  border-radius: 10px;
  border-color: #cbd3d3;
}

.bg-image {
  /* Add the blur effect */
  filter: blur(20px) drop-shadow(0 0 0 white);
  -webkit-filter: blur(20px) drop-shadow(0 0 0 white);

  /* Full height */
  height: 90%;
  width: 98%;
  margin-top: 1%;
  margin-left: 1%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 270px;
}

.rules-desc {
  font-family: fonts.$SF-Pro-Display;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 1.5rem;
  line-height: 16px;
  margin-top: 1rem;
}

.desc-container {
  padding: 24px;
}

.rank {
  width: 65px;
  display: flex;
  justify-content: center;
}

.title-heading {
  @extend .activity-style;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.see-more {
  cursor: pointer;
}

.filters-container {
  flex-direction: row;
  display: flex;
  justify-content: inherit;
}

.data-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter-padding {
  margin-right: 10px;
}
