.rating-header {
  display: grid;
  grid-template-columns: auto auto 100px 1fr auto;
  column-gap: 1rem;
  row-gap: 1rem;
  align-items: baseline;
}
.rating-num {
  font-weight: 400;
  font-size: 0.875rem;
}
