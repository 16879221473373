.card-image {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 200px;
}

.card-rounded {
  border-radius: 8px;
  box-shadow: -3px 9px 25px -7px rgba(8, 8, 8, 0.32);
  -webkit-box-shadow: -3px 9px 25px -7px rgba(8, 8, 8, 0.32);
  -moz-box-shadow: -3px 9px 25px -7px rgba(8, 8, 8, 0.32);
}

.card-description {
  font-size: 0.75rem;

  .card-text {
    display: flex;
  }
}

.divider-dot:after {
  content: "\A";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: gray;
  display: inline-block;
  vertical-align: middle;
}

.card-footer {
  margin-top: 0.6rem;

  :global(.ant-space-item) {
    font-size: 0.68rem;
  }
}
