@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
.text-center{
    text-align: center;
    width: fit-content;
    margin: 0 auto;
}
.title{
    font-family: fonts.$Source-Sans;
    color: theme.$default;
    font-weight: 700;
    font-size: 1.875rem;
    text-align: center;
    margin-bottom: 2.5rem;
    margin-top: 1rem;
}
.description{
    font-family: fonts.$Source-Sans;
    color: #adadad;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 4rem;
}
.btn-div {
    max-width: 270px;
    margin: 0 auto;
    margin-top: 1.5rem;
  }
  