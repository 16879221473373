@use "/src/assets/css/partials/fonts";
@import "/src/assets/css/mixins/mixins.scss";
.activity {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}

.deactivate-modal {
  justify-content: flex-end;
}

.list-id-content {
  display: inline-block;
}

.status-circle-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 5px;
}

.inprogress-circle {
  @extend .status-circle;
  background: #49bf26;
}

.planned-circle {
  @extend .status-circle;
  background: #0aaddf;
}

.btn-div {
  @include flex(space-between, center);
  margin-bottom: 20px;
}

.global-points-container {
  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 24px;

width: 100%;
height: 180px;
margin-bottom: 30px;

background: #FFFFFF;
border-radius: 8px;
}

.default-title {
  font-family: fonts.$Source-Sans;
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 20px;
}