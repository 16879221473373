@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

:global {
  .input-style {
    height: 3rem;
    background: theme.$white;
    border: 1px solid rgba(128, 128, 128, 0.16);
    border-radius: 0.75rem;
    font-family: fonts.$Source-Sans;
    font-weight: 400;
    font-size: 0.875rem;
  }

  .from-input.ant-input {
    @extend .input-style;
    &:focus {
      box-shadow: none;
    }
  }
  .from-input.ant-input-affix-wrapper {
    @extend .input-style;
    &:hover {
      border-color: rgba(128, 128, 128, 0.16);
    }
  }

  .ant-input-affix-wrapper.ant-input-password.from-input {
    @extend .input-style;

    &:focus {
      box-shadow: none;
    }
  }

  & .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  .from-input.ant-input-group-wrapper {
    .ant-input-group {
      .ant-input {
        @extend .input-style;

        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .ant-input-group-addon {
        @extend .input-style;

        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0;
        }
      }
    }
  }
  .rtl-input {
    font-family: "Almarai", sans-serif !important;
  }
}

.label {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 6px;
}

.form-control {
  margin-bottom: 1.5rem;
  position: relative;
}

.input-info {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
}
.input-info-right {
  position: absolute;
  top: 0.8rem;
  left: 1rem;
}
.info-top {
  top: 2.7rem;
}

.text-danger {
  color: red;
}
