@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
:global {
  @for $i from 1 through 1600 {
    .ant-checkbox-group.custom-pillar-selector {
      width: 100%;
      display: grid;
      row-gap: 1rem;
      grid-template-columns: repeat(6, auto);
      justify-content: space-between;
      .ant-checkbox-wrapper.pillar#{$i} {
        .ant-checkbox {
          display: none;
          + span {
            padding: 0;
          }
          &.ant-checkbox-checked + span {
            .hex {
              background-color: var(--pillar#{$i});
              img {
                filter: grayscale(0);
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .ant-checkbox-wrapper.custom-pillar-selector {
    .ant-checkbox {
      display: none;
    }
  }
}
.pillar-title {
  font-family: fonts.$Source-Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  color: theme.$primary-600;
  text-align: center;
  max-width: 90px;
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
