:global {
  .viwell-collapse.ant-collapse {
    overflow: hidden;
    border-radius: 0.5rem;
    .ant-collapse-item {
      .ant-collapse-header {
        .ant-collapse-expand-icon {
          span {
            font-size: 1rem;
          }
        }
      }
      .ant-collapse-content > .ant-collapse-content-box {
        border-radius: 0px 0px 8px 8px;
        padding-top: 1rem;
        background-color: #fafafa;
      }
    }
  }
  .pillar {
    border-radius: 0.5rem;
    overflow: hidden;
    .ant-collapse-item {
      .ant-collapse-header {
        .ant-collapse-expand-icon {
          span {
            font-size: 1rem;
          }
        }
      }
      .ant-collapse-content > .ant-collapse-content-box {
        border-radius: 0px 0px 8px 8px;
        padding-top: 1rem;
        background-color: #ebebeb;
      }
    }
  }

  .ant-collapse-arrow {
    padding: 7px !important;
    cursor: pointer;
  }
  .ant-collapse-icon-position-end
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    transform: translateY(-50%) rotate(0deg);
  }

  .ant-collapse-icon-position-end
    > .ant-collapse-item.ant-collapse-item-active
    > .ant-collapse-header
    .ant-collapse-arrow {
    transform: translateY(-50%) rotate(180deg);
  }
}
