:root {
  --pillar1: #f3f2ff;
  --pillar2: #fef3e4;
  --pillar3: #daf3f3;
  --pillar4: #e1f1fb;
  --pillar5: #ffe9e2;
  --pillar6: #ffedf8;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222b45;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  font-size: 16px;
}
