:global {
  .card-list {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .card-list .ant-row > div {
    gap: 24px;
  }

  .card-list.ant-list-grid .ant-col > .ant-list-item {
    margin-bottom: 24px;
  }
}

.mt {
  margin-top: 0.60rem;
}