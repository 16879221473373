@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.item-grid {
  display: grid;
  grid-template-columns: auto 1.4fr 1fr;
  column-gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .item-id {
    padding: 0px 0.5rem;
    text-align: center;
    width: 40px;
  }
  .sub-title {
    font-weight: 600;
    font-size: 0.875rem;
    font-family: fonts.$Source-Sans;
    color: #303030;
  }

  .users {
    text-align: right;
    font-family: fonts.$Source-Sans;
    font-size: 0.875rem;
    font-weight: 400;
  }
}
