@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.desc-grid {
  display: grid;
  grid-template-columns: 0.85fr 1fr;
  column-gap: 24px;
  .desc-container {
    border: 1px solid theme.$basic-500;
    padding: 1.25rem;
    border-radius: 8px;
    .image-grid {
      display: grid;
      grid-template-columns: 40px 120px 1fr;
      column-gap: 20px;
      .tierIcon {
        background-color: #d9d9d9;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 0.2rem;
        .tier-icon {
          width: 40px;
          height: 34px;
          object-fit: cover;
        }
      }
      .tier-image {
        border-radius: 8px;
        background-color: #d9d9d9;
        padding: 6px;
        height: 120px;
        width: 120px;
        .tierImg {
          width: 100%;
          height: 100%;
        }
      }
      .card-image {
        width: 100%;
        height: 120px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          border-radius: 8px;
        }
      }
    }
    .description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .title {
        font-weight: 700;
        font-size: 2rem;
        font-family: fonts.$Source-Sans;
        line-height: 36px;
      }
    }
  }
}
