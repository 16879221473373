@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
:global {
  .text-area-style {
    background: theme.$white;
    border: 1px solid rgba(128, 128, 128, 0.16);
    border-radius: 0.75rem;
    font-family: fonts.$Source-Sans;
    font-weight: 400;
    font-size: 0.875rem;
    &--intro {
      margin-bottom: 1rem;
      label {
        margin-bottom: 0;
      }
    }
  }

  .form-text-area {
    @extend .text-area-style;
  }
  .label {
    font-family: fonts.$Source-Sans;
    color: theme.$basic-900;
    font-weight: 600;
    font-size: 1rem;
    display: inline-block;
    margin-bottom: 6px;
  }
}

.form-control {
  margin-bottom: 1.25rem;
}
