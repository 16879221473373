@use "/src/assets/css/partials/fonts";
:global {
  .viwell-containerHeading {
    //styleName: Headline/h6;
    font-family: fonts.$Source-Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 0px;
    &--icon {
      margin-left: 1rem;
    }
  }
  .input-field-heading {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
}
