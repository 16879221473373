@use "/src/assets/css/partials/theme";
@use "/src/assets/css/partials/fonts";
:global {
  .Feedback-container {
    &__limitContainer {
      align-items: center;
      flex-wrap: wrap;
    }
    &--form-control {
      //   background-color: aqua;
      //   display: flex;
      //   justify-content: flex-start;
      .input-style {
        width: 50px;
        margin-left: 1rem;
      }
    }
    .custom-table {
      .custom-switch {
        margin-left: 14px;
      }
    }
  }
}
