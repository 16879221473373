.boolean-header {
  display: grid;
  grid-template-columns: 1fr 100px 1fr auto;
  column-gap: 1rem;
  row-gap: 1rem;
  align-items: baseline;
}
.boolean-header-emoji {
  @extend .boolean-header;
  grid-template-columns: auto 1fr 100px 1fr auto;
}
.sub-question {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.option-filter {
  @extend .boolean-header;
  grid-template-columns: 100px 1fr auto 50px;
}
