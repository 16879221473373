.option-header {
  display: grid;
  grid-template-columns: auto 1fr 100px 1fr auto;
  column-gap: 1rem;
  row-gap: 1rem;
}
.header {
  @extend .option-header;
  grid-template-columns: 1fr 100px 1fr auto;
}
.option-filter {
  @extend .option-header;
  grid-template-columns: 1fr auto auto auto;
}
.emoji-filter {
  @extend .option-header;
  grid-template-columns: auto 1fr auto auto;
}
.sub-question {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
