@use "/src/assets/css/partials/theme";
@use "/src/assets/css/partials/fonts";
:global {
  .input-style {
    height: 3rem;
    background: theme.$white;
    border: 1px solid rgba(128, 128, 128, 0.16);
    border-radius: 0.75rem;
    font-family: fonts.$Source-Sans;
    font-weight: 400;
    font-size: 0.875rem;
  }
  .custom-date.ant-picker {
    @extend .input-style;
    width: 100%;
  }
}
.form-control {
  margin-bottom: 1.5rem;
}
.label {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 600;
  font-size: 1rem;
  display: block;
  margin-bottom: 6px;
}

.subLabel {
  font-family: fonts.$Source-Sans;
  color: theme.$basic-900;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 6px;
}

.text-danger {
  color: red;
}
