$primary-hue: 0;
$primary-400: hsla($primary-hue, 0, 20, 1);
$primary-500: hsla($primary-hue, 0, 8, 1);
$primary-600: hsla($primary-hue, 0, 7, 1);
$primary-700: hsla($primary-hue, 0, 0, 1);
$purple: #7573e1;
$basic: hsla($primary-hue, 0, 15, 1);
$gray: hsla($primary-hue, 0, 50, 1);
$light-gray: hsla($primary-hue, 0%, 94%, 1);
$basic-200: hsla($primary-hue, 0%, 97%, 1);
$basic-400: #ebebeb;
$basic-500: #d6d6d6;
$basic-900: hsla($primary-hue, 0, 15%, 1);
$default: hsla(225, 34%, 20%, 1);
$black: #000000;
$app-bg: hsla($primary-hue, 0%, 96%, 1);
$white: #fff;
$success: #49bf26;
$danger: #ef4d56;
$info: #0aaddf;
$text-hint: #adadad;
