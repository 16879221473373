@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";

:global {
  .custom-modal.ant-modal {
    .ant-modal-content {
      background-color: theme.$white;
      box-shadow: 0px 8px 12px rgba(100, 116, 139, 0.16);
      border-radius: 0.5rem;

      .ant-modal-close {
        top: 1rem;
        right: 1rem;
        color: theme.$black;
      }

      .ant-modal-header {
        background-color: theme.$white;
        color: theme.$black;
        border-radius: 0.5rem 0.5rem 0px 0px;
        border-bottom: none;
        padding: 2rem 2rem 1rem 2rem;

        .ant-modal-title {
          font-family: fonts.$Source-Sans;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
        }
      }

      .ant-modal-body {
        padding: 2rem;
        // max-height: calc(100vh - 210px);
        // overflow-y: auto;
      }
    }
  }
  .header-bottom-border.ant-modal {
    .ant-modal-content {
      .ant-modal-header {
        border-bottom: 1px #f4f4f4 solid;
      }
    }
  }
}

.modal-footer {
  @include flex(space-between, center);
  border-top: 1px solid #e2e8f0;
  margin: 0 -2rem;
  padding: 1.25rem 2rem 0px 2rem;
}

.submit-btn {
  margin-left: 8px;
}
