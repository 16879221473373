@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";

.description {
  margin-bottom: 1.5rem;
  h2.desc {
    font-family: fonts.$Source-Sans;
    font-weight: 700;
    font-size: 1rem;
  }
  p {
    font-family: fonts.$Source-Sans;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 0px;
  }
}
