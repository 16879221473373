.assessment-create {
  .viwell-content {
    width: 100%;
    margin: 0 auto;
  }
  &-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .ant-collapse-header {
    background-color: #fff;
  }

  &-form,
  &-question-container {
    margin-top: 2rem;
    .viwell-containerHeading {
      margin: 0;
    }
    &.piller {
      background: #ebebeb;
      border-radius: 8px;
      overflow: hidden !important;
      &__addQuestion {
        width: 100%;
        .ant-space-item {
          margin: 1.5rem auto 0rem;
        }
      }
    }

    .ant-collapse-content-box {
      padding: 16px 24px 24px !important;
    }
  }

  .pillars {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
  }
}
