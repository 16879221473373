@use "/src/assets/css/partials/theme";
@use "/src/assets/css/partials/fonts";
:global {
  .viwell-breadcrumbs {
    font-family: fonts.$SF-Pro-Display;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #808080;
  }
}
