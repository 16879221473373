@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";

.add-content-header {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
  .title {
    font-family: fonts.$Source-Sans;
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 0px;
  }
}
.add-option {
  font-family: fonts.$Source-Sans;
  font-size: 1rem;
}
.viwell-checkbox {
  font-family: fonts.$Source-Sans;
  font-size: 1rem;
  display: flex;
  align-items: center;
}
.delete-div {
  display: flex;
  align-items: center;
}
.delete-icon {
  background-color: #f4f4f4;
  color: #231f20;
  border-style: none;
}
.name-desc {
  font-family: fonts.$SF-Pro-Display;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 1.5rem;
  line-height: 20px;
}
.multi-lang-box {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.5rem;
}
.flex-div {
  @include flex(space-between, center);
}
.add-btn {
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
}
.content-card {
  margin-bottom: 2rem;
}
.btn-div {
  @include flex(space-between, center);
  margin-bottom: 20px;
}
.activity-card {
  margin-bottom: 1rem;
}
.remove-btn {
  margin-bottom: 1.5rem;
  cursor: pointer;
  font-weight: 600;
}

.help-text {
  h4 {
    font-weight: bold;
  }

  p {
    font-size: 0.9rem;
    margin-bottom: 0.125rem;
  }

  p,
  h4 {
    color: white;
  }
}

.label-with-help {
  @include flex(flex-start, baseline);
  gap: 0.5rem;
}

.divider {
  @include flex(end, flex-start);
  gap: 8px;

  :global(.remove-btn) {
    margin-left: 1.25rem;
  }

  & > div {
    flex-grow: 1;
  }
}

.flex-end {
  @include flex(end, center);
  gap: 0.5rem;

  .add-btn {
    margin-top: 1.25rem;
  }
}

.subtitles {
  background-color: rgb(240 243 247 / 31%);
  margin-bottom: 1.5rem;
  padding: 1.5rem;
}

.challenge-img {
  width: 400px;
}

:global {
  .challenge-detail-container {
    margin-bottom: 1.5rem;
    .custom-upload
      .ant-upload-list-picture-card
      .ant-upload-list-item
      .ant-upload-list-item-info {
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
    .custom-upload .ant-upload.ant-upload-select-picture-card,
    .ant-upload-list-picture-card-container,
    .custom-upload .ant-upload-list-picture-card .ant-upload-list-item {
      border-radius: 8px;
      width: 100%;
      height: 200px;
      .ant-upload {
        .ant-space {
          align-items: center;
          padding: 1rem;
          .ant-space-item:nth-child(1) {
            width: 24px;
            height: 24px;
            margin-bottom: 1rem;
            img {
              border-radius: 0px;
            }
          }
          .ant-space-item:nth-child(2) {
            font-family: fonts.$SF-Pro-Display;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: center;
          }
        }
      }
    }
    .custom-upload .ant-upload-list-picture-card-container {
      display: none;
    }
  }
  .reward-image-container {
    .custom-upload .ant-upload.ant-upload-select-picture-card,
    .ant-upload-list-picture-card-container {
      height: 270px !important;
    }
  }
}
