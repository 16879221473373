@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.leaderboard-container {
  border: 1px solid theme.$basic-500;
  border-radius: 4px;
  padding: 4px 0px;
}
.item-grid {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  column-gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .members {
    font-family: fonts.$Source-Sans;
    font-size: 0.875rem;
    font-weight: 500;
    color: #121212;
  }
  .sub-title {
    font-size: 0.875rem;
    font-family: fonts.$Source-Sans;
    color: #303030;
    font-weight: 600;
  }
  .item-id {
    padding: 0px 0.5rem;
    text-align: center;
    width: 40px;
  }
  .users {
    text-align: right;
    .count {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1rem;
      color: #121212;
      text-align: right;
      width: 96px;
    }
  }
}
.pagination-margin {
  margin: 1rem 0px;
}

.mb-1 {
  margin-bottom: 1rem;
}
