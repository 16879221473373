@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
.uploader {
  margin-bottom: 2rem;
}
.center-div {
  width: fit-content;
  margin: 36px auto;
  .select-file {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 24px;
  }
  .file-size {
    font-weight: 400;
    font-size: 14px;
    color: theme.$gray;
  }
  .downloadLink {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    color: black;
    display: inline-block;
    margin-bottom: 1.5rem;
  }
}
