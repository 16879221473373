@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
.form-control {
  margin-bottom: 1.5rem;
}
.center-div {
  width: fit-content;
  margin: 0 auto;
}
.select-file {
  font-family: fonts.$Inter;
  font-weight: 700;
  margin: 0.875rem 0;
}
.file-size {
  font-weight: 400;
  font-size: 14px;
  color: theme.$gray;
  margin-bottom: 1.25rem;
}
.downloadLink {
  color: black;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  column-gap: 12px;
}

.downloadIcon {
  color: black;
  font-size: 20px;
  margin-left: 5px;
}

.preview-container {
  .data-table-container {
    height: 550px;
    .data-table-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.8rem;
      .row-item-spacing {
        margin-right: 2rem;
      }
      .row-flex {
        flex: 1;
      }
      .row-header {
        color: #303030;
        font-family: fonts.$SF-Pro-Display;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.125rem;
      }
    }
  }
  .fit-container-width {
    width: fit-content;
  }
}

.preview-content-scroll {
  overflow-x: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  .preview-content-scroll::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }
}

.errors-container {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  .error-text {
    margin-left: 0.5rem;
    color: #f57c78;
    font-family: fonts.$Source-Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}

.detected-errors-spacing {
  margin-top: 1rem;
}

.collapse-container {
  display: flex;
}

.error-content-spacing {
  margin-left: 0.5rem;
}

.error-desc-spacing {
  margin-left: 1.7rem;
}
