@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.item-grid {
  display: grid;
  grid-template-columns: 1.3fr 1fr auto;
  column-gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .participants {
    font-family: fonts.$Source-Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #636363;
    text-decoration: underline;
    cursor: pointer;
    text-underline-offset: 4px;
    text-decoration-thickness: 1.5px;
  }
  .sub-title {
    font-weight: 600;
    font-size: 0.875rem;
    font-family: fonts.$Source-Sans;
    color: #303030;
  }
  .item-id {
    padding: 0px 0.5rem;
    text-align: center;
    width: 40px;
  }
  .users {
    text-align: right;
    .count {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1rem;
      color: #121212;
      text-align: right;
      width: 96px;
    }
  }
}
