@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
:global {
  .tab-style {
    font-family: fonts.$Inter;
    font-weight: 500;
    font-size: 1rem;
    color: theme.$primary-600;
  }
  .custom-tabs.ant-tabs {
    .ant-tabs-nav {
      &::before {
        border-color: theme.$basic-400;
      }
    }
    .ant-tabs-tab {
      @extend .tab-style;
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: theme.$primary-600;
          font-weight: 700;
        }
      }
      &:hover {
        color: theme.$primary-600;
      }
      .ant-tabs-tab-btn:active {
        color: theme.$primary-600;
      }
    }
    .ant-tabs-ink-bar {
      background-color: theme.$primary-500;
    }
  }
}
