@use "/src/assets/css/partials/fonts";
@use "/src/assets/css/partials/theme";
@import "/src/assets/css/mixins/mixins.scss";
.collection {
  @include flex(space-between, center);
  margin-bottom: 1.25rem;
}
.title {
  font-family: fonts.$Source-Sans;
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 0px;
}
.collection-title {
  font-family: fonts.$Source-Sans;
  font-weight: 700;
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
}
.description {
  margin-bottom: 1.5rem;
  h2.desc {
    font-family: fonts.$Source-Sans;
    font-weight: 700;
    font-size: 1rem;
  }
  p {
    font-family: fonts.$Source-Sans;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 0px;
  }
}
.collection-img {
  margin-bottom: 1.5rem;
  img {
    max-width: 100%;
    object-fit: cover;
    border-radius: 1rem;
    max-height: 350px;
  }
}
.collection-table {
  margin: 2rem 0px;
}
